/// <reference path="/dist/scripts/!shared/angular/angular-bundle.js"
/// <reference path="/dist/scripts/!shared/jquery/jquery-bundle.js"

(function (app) {
    'use strict';

    app.run(['$rootScope', function ($rootScope) {
        var debugWatch = function (id, oldVal, newVal) {
            console.log('"' + id + '" watch(old, new):');
            console.log(oldVal);
            console.log(newVal);
            //debugger
        };
        //$rootScope.watch('searchZone', debugWatch);
        //$rootScope.searchZone.watch('location', debugWatch);
    }]);
    app.service('helper', [function () {
        return {
        };
    }]);
    app.filter('html', ['$sce', function ($sce) {
        return function (val) {
            return $sce.trustAsHtml(val);
        };
    }]);

    app.service("newsItem", ['$location', '$http', '$httpParamSerializer',
        function ($location, $http, $httpParamSerializer) {
            this.lastNewsSent = false;
            var defaultSkip = 0, defaultTake = 3;

            var loadNews = function (skip, take) {
                if (typeof skip === 'undefined') skip = defaultSkip;
                if (typeof take === 'undefined') take = defaultTake;

                var params = { "news": true, "skip": skip, "take": take };
                var queryString = $httpParamSerializer(params);

                return $http.get($location.path() + "?" + queryString)
                    .then(function (response) {
                        var result = null;
                        if (response && response.data) {
                            result = angular.fromJson(response.data);

                            result.news.forEach(function (obj) {
                                obj.body = obj.body.replace(/\<iframe(.*)\>(.*)\<\/iframe\>/, "");
                            });
                        }
                        return result;
                    });
            }

            var get = function (skip, take) {
                //return this.allItems.slice(skip, skip + take);
                return loadNews(skip, take);
            };

            return {
                get: get
            };
        }]);

    app.controller("PaginationCtrl", ['$scope', '$timeout', '$uibModal', '$log', '$http', 'newsItem',
        function ($scope, $timeout, $uibModal, $log, $http, newsItem) {
            $scope.items = ['item1', 'item2', 'item3'];
            $scope.animationsEnabled = true;

            var news = newsItem;
            $scope.itemsPerPage = 3;
            $scope.currentPage = 0;
            $scope.lastNewsSent = false;
            $scope.pagedItems = [];

            $scope.open = function ($event, link, size) {
                $event.preventDefault();
                if (!link) {
                    link = $event.delegateTarget.href;
                }
                //$log.info("this is the link: " + link);

                $http.get(link).then(function (response) {
                    var modalInstance = $uibModal.open({
                        animation: $scope.animationsEnabled,
                        templateUrl: 'newsModal.html',
                        controller: 'NewsModalInstanceCtrl',
                        size: size,
                        resolve: {
                            item: function () {
                                return response.data.item;
                            }
                        }
                    });

                    modalInstance.result.then(function (selectedItem) {
                        $scope.selected = selectedItem;
                    }, function () {
                        $log.info('Modal dismissed at: ' + new Date());
                    });
                });
            }

            var readAnswer = function (response) {
                if (response) {
                    $timeout(function () {
                        $scope.pagedItems = $scope.pagedItems.concat(response.news);
                        $scope.lastNewsSent = response.lastNewsSent;
                        $scope.$apply();
                    });
                }
            }
            // load first page
            news.get($scope.currentPage * $scope.itemsPerPage, $scope.itemsPerPage)
                .then(readAnswer);

            // load other pages
            $scope.loadMore = function () {
                $scope.currentPage++;
                news.get($scope.currentPage * $scope.itemsPerPage, $scope.itemsPerPage)
                    .then(readAnswer);
            };

            $scope.nextPageDisabledClass = function () {
                return $scope.lastNewsSent ? "disabled" : "";
            };
        }]);

    app.controller('NewsModalInstanceCtrl', ['$scope', '$uibModalInstance', 'item',
        function ($scope, $uibModalInstance, item) {
            $scope.item = item;
            $scope.selected = {
                item: item
            };

            $scope.ok = function () {
                $uibModalInstance.close($scope.selected.item);
            };

            $scope.cancel = function () {
                $uibModalInstance.dismiss('cancel');
            };
        }]);

    app.controller("CarouselNewsItemCtrl", ['$scope',
        function ($scope) {
            $scope.myInterval = 5000;
            $scope.noWrapSlides = false;
        }]);

    app.directive("owlCarousel", function () {
        return {
            restrict: 'E',
            transclude: false,
            link: function (scope) {
                scope.initCarousel = function (element) {
                    setCarousel("#popupCarousel", 1, true, true);
                };
            }
        };
    })

    app.directive('owlCarouselItem', [function () {
        return {
            restrict: 'A',
            transclude: false,
            link: function (scope, element) {
                // wait for the last item in the ng-repeat then call init
                if (scope.$last) {
                    scope.initCarousel(element.parent());
                }
            }
        };
    }]);

    app.filter('trustAsResourceUrl', ['$sce', function ($sce) {
        return function (val) {
            return $sce.trustAsResourceUrl(val);
        };
    }]);

    app.config(['$locationProvider', '$httpProvider', function ($locationProvider, $httpProvider) {
        // to have the correct relative $location.path()
        $locationProvider.html5Mode({ enabled: true, requireBase: false });
        // to have a "correct" AJAX request when $http.get()
        $httpProvider.defaults.headers.common["X-Requested-With"] = 'XMLHttpRequest';
    }]);
})(angular.module('entityApp', ['ngSanitize', 'ngAnimate', 'ui.bootstrap']));
//////////////////////////////////////////////////////////////////////////////////

/*
 * object.watch polyfill
 *
 * 2012-04-03
 *
 * By Eli Grey, http://eligrey.com
 * Public Domain.
 * NO WARRANTY EXPRESSED OR IMPLIED. USE AT YOUR OWN RISK.
 */

// object.watch
if (!Object.prototype.watch) {
    Object.defineProperty(Object.prototype, "watch", {
        enumerable: false
        , configurable: true
        , writable: false
        , value: function (prop, handler) {
            var
                oldval = this[prop]
                , newval = oldval
                , getter = function () {
                    return newval;
                }
                , setter = function (val) {
                    oldval = newval;
                    return newval = handler.call(this, prop, oldval, val);
                }
                ;

            if (delete this[prop]) { // can't watch constants
                Object.defineProperty(this, prop, {
                    get: getter
                    , set: setter
                    , enumerable: true
                    , configurable: true
                });
            }
        }
    });
}

// object.unwatch
if (!Object.prototype.unwatch) {
    Object.defineProperty(Object.prototype, "unwatch", {
        enumerable: false
        , configurable: true
        , writable: false
        , value: function (prop) {
            var val = this[prop];
            delete this[prop]; // remove accessors
            this[prop] = val;
        }
    });
}